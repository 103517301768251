import Vue from 'vue';

const dictionary = {
  ru: {
    noData: 'Нет данных',
    // placeholders
    choose: 'Выберите',
    amount: 'Введите сумму',
    email: 'Введите почту',
    phone: 'Введите номер телефона',
    tg: 'Введите телеграмм',
    names: 'Введите ФИО',
    wallet: 'Введите кошелёк',
    sbpphone: 'Введите номер СБП',
    card: 'Введите номер карты',
    bank: 'Введите название банка',
    bankAccountNumber: 'Введите номер счёта',
    contactInfo: 'Введите телефон / телеграмм',

    // pay form 
    send: 'Отдаёте',
    get: 'Получаете',
    contactData: 'Контактные данные',
    withdrawalData: 'Данные для вывода',
    amlAgree: 'Соглашаюсь с политикой ',
    reserve: 'Резерв',
    createOrderBtn: 'Обменять',
    refetchRate: 'Обновляем курсы',
    chooseDirection: 'Выберите валюту для получения',
    fillField: 'Заполните поле',

    //shit
    banks: 'Банки',
    cryptocurrency: 'Криптовалюты',
    fiat: 'Наличные',

    name: 'Имя',
    mail: 'Почта',
    phoneNumber: 'Телефон',
    orderAmount: 'Объём заявок',
    history: 'История',
    refProgram: 'Реф. программа',
    refProgramText1: 'Получайте ',
    refProgramText2: '% от объёма всех обменов ваших рефералов, вы можете пригласить неограниченое число рефералов по своей ссылке и они закрекпляются за вами навсегда. Реферальный бонус начисляется в USDT, вывод возможен от 100 USDT.',
    refBallance: 'Доступно',
    generateRefCode: 'Создать код',
    enterTRC20address: 'Введите адрес TRC20',
    enterAmount: 'Введите сумму',
    withdrawalAll: 'Вывести всё',
    refFrozen: 'На выводе',
    withdraw: 'Вывести',
    withdrawals: 'Выводы',
    address: 'Адрес',
    amountTitle: 'Сумма',
    status: 'Статус',
    cards: 'Карты',
    bankTitle: 'Банк',
    number: 'Номер',
    settings: 'Настройки',
    changePassword: 'Изменить пароль',
    editProfile: 'Редактировать профиль',
    addCard: 'Добавить карту',
    deleteAccount: 'Удалить аккаунт',
    enterNewPassword: 'Введите новый пароль',
    enterNewPasswordAgain: 'Введите новый пароль повторно',
    enterOldPassword: 'Введите старый пароль',
    cancel: 'Отмена',
    save: 'Сохранить',
    enterCardNumber: 'Введите номер карты',
    enterBankName: 'Введите название банка',
    enterFullName: 'Введите ФИО',
    loadCardPhoto: 'Загрузить фото карты',
    deleteAccountText: 'Для удаления вашего аккаунта и всей информации связаной с ним введите в поле слово - BITCOIN',
    enterWord: 'Введите слово',
    delete: 'Удалить',
    enterEmail: 'Введите почту',
    enterPhone: 'Введите телефон',
    enterName: 'Введите имя',
    enterSurname: 'Введите фамилию',
    noExchange: 'Нет обменов',
    created: 'Создана',
    sended: 'Отдали',
    geted: 'Получили',

    NEW: 'Новый',
    PENDING: 'В обработке',
    PAID: 'Поступила оплата',
    COMPLETED: 'Завершен',
    TIMEEND: 'Истекло время',
    AMLRISK: 'AML риск',
    CANCELED: 'Отменена',
    ERROR: 'Ошибка',
    PARTIALLYPAID: 'Оплачен частично',
    account: 'Аккаунт',
    noHistory: 'Нет истории',
    noCards: 'Нет карт',

    exchange: 'Обменять',
    feedback: 'Отзывы',
    services: 'Услуги',
    news: 'Новости',
    rules: 'Правила',
    aml: 'KYC / AML',
    contact: 'Контакты',
    partnerProgram: 'Партнёрская программа',
    rates: 'Тарифы',
    faq: 'FAQ',
    chart: 'Графики',

    offers: 'Оферты',
    login: 'Войти',
    markets: 'Рынки',
    orders: 'Заявки',
    wallets: 'Кошельки',
    partners: 'Партнёры',
    redirects: 'Переходы',
    statistics: 'Статистика',
    users: 'Пользователи',
    pages: 'Страницы',
    blockLists: 'Черный список',
    logout: 'Выйти',

    registration: 'Регистрация',
    passwordAgain: 'Повторите пароль',
    enterPassword: 'Введите пароль',
    password: 'Пароль',
    enterPasswordAgain: 'Введите пароль повторно',
    refCode: 'Реферальный код',
    enterRefCode: 'Введите реферальный код',
    signupAgree1: 'Я согласен с',
    signupAgree2: 'условиями и правилами сервиса',
    signUp: 'Зарегистрироваться',
    isAccount: 'Уже есть аккаунт?',

    noAccount: 'Ещё нет аккаунта?',
    forgotPassword: 'Забыли пароль?',
    chooseAccountType: 'Выберите тип аккаунта',
    user: 'Пользователь',
    monitoring: 'Мониторинг',
    partner: 'Партнёрская программа',
    partnerTitle: 'Партнёр',
    restorePassword: 'Восстановление пароля',
    restore: 'Восстановить',
    getRestoreInstruction: 'Получить инструкцию для входа',

    partnerRegistration: 'Регистрация Партнёра',

    commission: 'Комиссия',
    balance: 'Баланс',
    link: 'Ссылка',
    ratesLink: 'Ссылка на курсы',
    createWithdrawalRequest: 'Создать заявку на вывод',
    withdrawalHistory: 'История вывода',
    noWithdrawalHistory: 'Нет истории выводов',
    title404: 'Ничего не нашли, пора домой',
    goHome: 'Домой',
    commissionHistory: 'История отчислений',

    date: 'Дата',
    profit: 'Маржа',

    feedbackComponentTitle: 'Отзывы наших клиентов',
    feedbackComponentSubtitle: 'Наши последние заявки',
    openAllFeedback: 'Просмотреть все отзывы',
    marketsOverwie: 'Обзор рынков',
    marketsOverwieCoinName: 'Название',
    marketsOverwieRate: 'Курс в $',
    marketsOverwieChange: 'Изменение за день',
    marketsOverwieMarketcap: 'Капитализация',
    marketsOverwieTradingVolume: 'Объём торгов',
    buyBtn: 'Купить',
    chartBtn: 'График',
    workflowComponentTitle: 'Как это работает',
    workflowComponentSubtitle: 'Работа нашего сервиса прошла много стадий эволюции чтобы дать пользователям лучший опыт обмена криптовалют.',
    workflowComponent1: 'Инфраструктура',
    workflowComponent1sub: 'Наш сервис базируется на передовых технологиях и разработчики следуют в ногу с современными технологиями.',
    workflowComponent2: 'Монеты',
    workflowComponent2sub: 'Мы постоянно добавляем новые направления для обмена, если вы не нашли того что вам нужно, просто напишите менеджеру и он сможет найти выход.',
    workflowComponent3: 'Комиссии',
    workflowComponent3sub: 'Мы стараемся держать комиссии на минимальном уровне чтобы вы могли свободно конвертировать и приобретать криптовалюту.',
    workflowComponent4: 'Данные',
    workflowComponent4sub: 'Все данные которые обрабатывает наш сервис, хранится на самых безопасных сервера и вы можете не беспокоиться о безопасности.',
    newsComponentTitle: 'Изучай и зарабатывай',
    newsComponentSubtitle1: 'Самые свежие новости из мира финансов и криптовалют.',
    newsComponentSubtitle2: 'Кто владеет информацией – тот владеет миром (Натан Ротшильд)',
    newsComponentBtn: 'Читать все новости',
    partnersComponentTitle: 'Наши партнёры',
    partnersComponentSubtitle: 'Список наших партнёров постоянно пополняется, вы так же можете присоедениться к нашей команде.',
    registrationAsPartner: 'Зарегистрироваться как партнёр',
    askManeger: 'Уточнить детали у менеджера',
    createAccount: 'Создать аккаунт',

    pageFeedbacksTitle: 'Отзывы',
    pageFeedbacksSubtitle: 'Почему пользователи доверяют нам и выбирают нас',
    pageFeedbacksBtn: 'Совершить обмен',
    pageFeedbacksSubjectTitle1: 'Множество направлений',
    pageFeedbacksSubjectSubtitle1: 'Благодаря нашей платформе вы можете познакомиться с криптовалютами и выбрать из множества вариантов обмена',
    pageFeedbacksSubjectTitle2: 'Оптимизация',
    pageFeedbacksSubjectSubtitle2: 'Наши разработчики оптимизировали платформу чтобы вам было удобно использовать её с любого устройства и любой точки мира',
    pageFeedbacksSubjectTitle3: 'Безопасность',
    pageFeedbacksSubjectSubtitle3: 'Наш продукт построен с использованием самых передовых технологий в сфере безопасности, все ваши данные и активы всегда находятся в абсолютной безопасности',

    pageServicesSubtitle1: 'На этой странице вы можете ознакомиться со всем перечнем наших сервисов и услуг которые мы предоставляем.',
    pageServicesSubtitle2: 'Если у вас есть вопросы или вы не нашли нужной вам услуги, свяжитесь с нами в телеграмм -',

    pageNewsTitle: 'Всё самое важное из мира криптовалют',

    orderPageMetaTitle: 'Заявка',

    orderLoginFormTitle: 'Аккаунт с этой почтой существует, пожалуйста войдите в свой аккаунт чтобы добавить карту',
    orderRateTitle: 'Курс обмена',
    orderCreateTitle: 'Заявка создана',
    orderPayoutTitle: 'Счёт получателя',
    orderEmailTitle: 'Контактная почта пользователя',
    orderPhoneTitle: 'Контактный номер пользователя',
    orderUserContactDataTitle: 'Контактный данные пользователя',
    orderCardTitle: 'Карта для оплаты',
    orderCardNumberTitle: 'Номер карты',
    orderNameTitle: 'ФИО получателя',
    orderChooseCardTitle: 'Выбор карты',
    orderWaitForCardTitle: 'Получаем реквизиты для оплаты, это займёт несколько мгновений',
    orderChoose: 'Выбрать',
    cancelOrder: 'Отменить заявку',
    iPaid: 'Я оплатил',
    refreshOrder: 'Обновить заявку',
    contactManadger: 'Связь с менеджером',
    writeFeedback: 'Введите отзыв о нашей работе...',
    loadMore: 'Увеличить',
    cookieTitle: 'Мы используем cookie.',
    okay: 'Хорошо',
    leaveFeedback: 'Оставить отзыв',
    thanksFeedback: 'Спасибо за отзыв',

    tgManager: 'Менеджер',

    mailWithInstructionSended: 'Письмо с инструкцией отправленно',
    cardAddedSucces: 'Карта успешно выбрана',
    errorCardChoose: 'Ошибка выбора карты',
    userAlreadyCreated: 'Такой пользователь уже существует',
    chechImgLoaded: 'Фото чека загружено',
    vaitCardVerif: 'Дождитесь верификации карты',
    chooseCard: 'Выберите карту',
    fillAllFieldAndLoadCard: 'Заполните все поля и загрузите карту',
    imgCardLoaded: 'Фото карты загружено',
    feedbackAdded: 'Отзыв сохранён',
    doYouRealyWantToCancelOrder: 'Вы уверены что хотите отменить заявку и отказаться от зафиксированого курса?',
    hide: 'Свернуть',
    show: 'Подробнее',
    userFeedbackTitle: 'Пользователь',
    directionFeedbackTitle: 'Направление',
    currencys: 'Валюты',
    time: 'Время',
    refCodeGenerated: 'Код создан',
    withdrawCreated: 'Вывод отправлен в обработку',
    withdrawMin100: 'Вывод доступен от 100 USDT',
    withdrawSetMin100: 'Укажите сумму больше 100 USDT',

    widthwalCreated: 'Вывод создан',
    type: 'Тип',
    privetKey: 'Приватный ключ',
    widthwal: 'Вывод',
    copy: 'Скопировать',
    privetKeyCopied: 'Приватный ключ скопирован',
    active: 'Активные',
    enterOrderID: 'Введите ID заявки',
    enterAddress: 'Введите адрес',
    enterWalletType: 'Выберите тип кошелька',
    findRefetch: 'Найти / Обновить',
    reset: 'Сбросить',
    used: 'Использованые',

    deleteUserAgree: 'Вы уверены что хотите удалить пользователя?',
    blockUserAgree: 'Вы уверены что хотите заблокировать',
    ban: 'Заблокировать',
    done: 'Сделано!',
    errorUserCalculate: 'Ошибка вычисления пользователя',
    roles: 'Роли',
    userData: 'Данные пользователя',
    userOrderVolume: 'Объём сделок',
    refBalance: 'Реф Баланс',
    refOwner: 'Рефовод',
    refComission: 'Комиссия от рефералов',
    currency: 'Валюта',
    rate: 'Курс',
    addressForSend: 'Адрес вывода',
    chooseStatus: 'Выберите статус',
    cardTitle: 'Карта',
    open: 'Открыть',
    start: 'Начало',
    end: 'Конец',
    totalOrders: 'Всего заявок',
    volume: 'Объём',
    medianVolume: 'Средний объём',
    medianProfit: 'Средняя маржа',
    medianRate: 'Средний курс',
    allProfit: 'Общая маржа',
    getTitle: 'Получено',
    sendTitle: 'Отправленно',
    enterIP: 'Введите IP',
    choosePartner: 'Выберите партнёра',
    notMonitoring: 'Не мониторинг',
    monitoringOrRid: 'Мониторинг / RID',
    submitDeleteCoin: 'Вы уверены что хотите удалить монету?',
    hideSettings: 'Скрыть настройки',
    showSettings: 'Показать настройки',
    maintenance: 'Тех работы',
    xmlStatus: 'Парсинг по XML',
    coinName: 'Отображаемок имя',
    coinSymbol: 'Символ монеты',
    coinChain: 'Сеть / Город / Банк',
    coinLogo: 'Логотип',
    coinType: 'Тип монеты',
    coinCode: 'Код валюты',
    activeDirection: 'Активные направления',
    depositFields: 'Поля для депозита',
    withdrawFields: 'Поля для вывода',
    coinSymbolsEnd: 'Знаки после запятой',
    depositSymbols: 'Округление вывода',
    depositCommission: 'Коммиссия депозита',
    withdrawCommission: 'Комиссия вывода',
    coinReserve: 'Резерв валюты',
    coinPosition: 'Позиция в списке',
    officeAddress: 'Адрес офиса',
    mapLink: 'Ссылка на карту',
    walletType: 'Тип кошелька',
    crypto: 'Криптовалюта',
    tgTitle: 'Телеграмм',
    tag: 'Тэг',
    namesTitle: 'ФИО',
    walletTitle: 'Кошелёк',
    sbpPhone: 'Номер СБП',
    bankAccountNumberTitle: 'Номер счёта',
    custom: 'Кастомные',
    canGet: 'Принимаем',
    canSend: 'Выводим',
    addCoin: 'Добавить монету',
    deleteCoin: 'Удалить выбранную монету',
    fillDirectionDescription: 'Заполните описание обмена',
    market: 'Рынок',
    fetchedRate: 'Курс с биржи',
    fixRate: 'Фиксированый курс',
    profitRate: 'Маржа / Курс',
    min: 'Минимум',
    max: 'Максимум',
    updateRates: 'Обновить курсы',
    saveDirection: 'Сохранить направления',
    notActive: 'Не активно',
    deletePartnerAgree: 'Вы уверены что хотите удалить партнёра?',
    enterReason: 'Введите причину',
    partnerName: 'Название',
    commissionFromProfit: 'Комиссия от маржи',
    removeBan: 'Разблокировать',
    chooseComponentsToAddToPage: 'Выберите компоненты которые будут добавлены на страницу.',
    header: 'Заголовок',
    description: 'Описание',
    enterDescriptionWithHtml: 'Введите описание с HTML тэгами',
    faqPage: 'Вопросы и ответы',
    question: 'Вопрос',
    answer: 'Ответ',
    deleteFAQ: 'Удалить "Вопрос - Ответ"',
    addFAQ: 'Добавить "Вопрос - Ответ"',
    components: 'Компоненты',
    loadPhoto: 'Загрузить фото',
    pasteLinkForSiteLogo: 'Вставьте ссылку на логотип сайта',
    pasteTGSupportLink: 'Вставьте ссылку на телеграмм поддержки',
    text: 'Текст',
    footerLeftText: 'Вставьте текст в левом нижнем углу',
    HTMLFooterLeft: 'HTML левый угол футера',
    enterHTMLFooterLeft: 'Вставьте контент в левый угол футера',
    HTMLFooterRight: 'HTML правый угол футера',
    enterHTMLFooterRight: 'Вставьте контент в правый угол футера',
    showPartners: 'Отображать партнёров?',
    showSitemap: 'Отображать карту сайта?',
    chooseLinkForFooter: 'Выберите какие ссылки будут доступны в карте сайте в футере',
    enterHeaderSection: 'Введите заголовок секции',
    gapFromTopInPX: 'Отступ от верхней компоненты на страницах в пикселях',
    enterGap: 'Введите отступ',
    linksForPartners: 'Ссылки на партнёров и их логотип',
    partnerLink: 'Ссылка на партнёра',
    enterLink: 'Введите ссылку',
    partnerPhoto: 'Ссылка на фото',
    addPartner: 'Добавить партнёра',
    officeLocationList: 'Список ваших офисов с адресами и картами',
    enterGMlink: 'Вставьте ссылку из Google карты',
    addLocation: 'Добавить локацию',
    showAllOrdersInFeedbacks: 'Показывать все завершенные заявки в отзывах',
    orderHeader: 'Заголовок заявки',
    walletHeader: 'Заголовок кошелька',
    addressHeader: 'Заголовок адреса',
    instructionHeader: 'Заголовок инструкции',
    enterSectionHeader: 'Введите заголовок секции',
    linkForManager: 'Ссылка на менеджера',
    enterText: 'Введите текст',
    instructionTIMEEND: 'Инструкция для заявки Просрочен - TIMEEND',
    instructionAMLRISK: 'Инструкция для заявки АМЛ риск - AMLRISK',
    instructionCANCELED: 'Инструкция для заявки Отменена - CANCELED',
    instructionCOMPLETED: 'Инструкция для заявки Завершена - COMPLETED',
    instructionPAID: 'Инструкция для заявки Оплачена - PAID',
    instructionPARTIALLYPAID: 'Инструкция для заявки частино оплаченой - PARTIALLYPAID',
    instructionCryptoCrypto: 'Инструкция для заявки крипта -> крипта (NEW, PENDING)',
    instructionCryptoFiat: 'Инструкция для заявки крипта -> наличные (NEW, PENDING)',
    instructionFiatCrypto: 'Инструкция для заявки с наличными -> крипта (NEW, PENDING)',
    instructionCryptoCard: 'Инструкция для заявки крипта -> карта (NEW, PENDING)',
    instructionCardCrypto: 'Инструкция для заявки карта -> крипта (NEW, PENDING)',
    setOrAddCardText: 'Текст при добавлении или выборе карты',
    addCardText: 'Текст при добавлении карты новым пользователем',
    cardInstruction: 'Общая инструкция в секции карты',
    techAlert: 'Техническое уведомление',
    enterHeader: 'Введите заголовок',
    promoAlert: 'Акционное уведомление',
    fullDescription: 'Подробное описание',
    jivoLink: 'Ссылка на JIVO chat',
    metrikaScript: 'Скрипт Yandex.Metrika',
    enterScript: 'Введите скрипт',
    metrikaLink: 'Ссылка Yandex.Metrika',
    vantajsLink: 'Ссылка на vantajs фон',
    configVanta: 'Конфигурация vantajs',
    enterConfig: 'Введите конфигурацию',
    customCSS: 'Кастомные стили css',
    enterCss: 'Введите css',
    showTimerOnBtn: 'Отображать таймер на кнопке?',
    widthPayForm: 'Ширина формы заявки в пикселях',
    enterWidth: 'Введите ширину',
    defaultExchangeDirection: 'Дефолтное направление обмена на главной странице',
    enterRequest: 'Введите запрос',
    chooseReferral: 'Выберите реферала',
    agreeDeleteOrder: 'Вы уверены что хотите удалить заявку?',
    refOwnerId: 'ID рефовода',
    userSend: 'Отдаёт',
    userPaid: 'Оплатил',
    userGet: 'Получает',
    refOwnerProfit: 'Маржа рефовода',
    depositAddress: 'Адрес депозита',
    cardId: 'ID карты',
    withdrawUser: 'Вывод пользователю',
    recipients: 'Чеки',
    orderInfo: 'Заметка',
    takeToWork: 'Принять в работу',
    noRate: 'Нет нужного курса',
    idTime: 'ID / Время',
    statusRefOwner: 'Статус / Рефовод',
    sendPaid: 'Отдаёт / Оплатил',
    pauInfo: 'Реквизиты',
    deleteMarketAlert: 'Данный рынок включён в расчёт курсов, после удаления рынка обновите странницу курсов чтобы направления, где участвует этот рынок было рассчитано корректно.',
    confirmDeleteMarket: 'Вы уверены что хотите удалить рынок?',
    addMarket: 'Добавить рынок',
    cross: 'Кросс',
    test: 'Протестировать',
    update: 'Обновить',
    sell: 'Продажа',
    buy: 'Покупка',
    newDirection: 'Новое направление',
    userId: 'ID пользователя',
    userEmail: 'Почта пользователя',
    deleteCard: 'Удалить карту',
    reason: 'Причина',
    blockedIps: 'Заблокированные IP',
    blockedUsers: 'Заблокированные пользователи',
    blockedPartners: 'Заблокированные партнёры',

    loading: 'Загрузка',
    online: 'Онлайн',
    offline: 'Не в сети',
    onlineOperatorInfo: 'Оператор в сети и любая заявка будет обработана в кратчайшие сроки.',
    offlineOperatorInfo: 'Оператор не в сети, время обработки заявок может быть увеличено.',
    initPayout: 'Производим выплату',
    yes: 'Да',

    promotionTitle: 'Оставь отзыв и получи до 500 рублей !',
    promotionSubtitle1: 'Совершите обмен, оставьте отзыв о нас и получите до 500 рублей! (100 за каждый отзыв)',
    promotionSubtitle2: 'Для получения бонуса отправьте службе поддержки в телеграмм номер вашей заявки и ссылку на отзыв.',
    promotionSubtitle3: 'Ниже представлены все платформы где можно оставить отзыв. Просто нажмите на название для перехода.',

    ERRORDETAILS: 'Не верные реквизиты',
    deposit: 'Депозит',
    instructionERRORDETAILS: 'Инструкция для заявки где не верно указаны реквизиты - ERRORDETAILS',

  },
  en: {
    ERRORDETAILS: 'Wrong details',
    deposit: 'Deposit',

    choose: 'Choose',
    amount: 'Enter amount',
    email: 'Enter email',
    phone: 'Enter phone number',
    tg: 'Enter telegram',
    names: 'Enter names',
    wallet: 'Enter wallet',
    sbpphone: 'Enter SBP phone',
    card: 'Enter card number',
    bank: 'Enter bank name',
    bankAccountNumber: 'Enter bank account number',
    contactInfo: 'Enter phone / telegram',

    // pay form 
    send: 'Send',
    get: 'Receive',
    contactData: 'Contact info',
    withdrawalData: 'Withdrawal info',
    amlAgree: 'I agree with ',
    reserve: 'Reserve',
    createOrderBtn: 'Exchange',
    refetchRate: 'We update exchange rates',
    chooseDirection: 'Select the currency to receive',
    fillField: 'Fill in the field',

    //shit
    banks: 'Banks',
    cryptocurrency: 'Cryptocurrencies',
    fiat: 'Cash',

    name: 'Name',
    mail: 'Email',
    phoneNumber: 'Phone',
    orderAmount: 'Order amount',
    history: 'History',
    refProgram: 'Ref. program',
    refProgramText1: 'Get ',
    refProgramText2: '% depending on the volume of all exchanges of your referrals, you can invite an unlimited number of referrals using your link and they will be assigned to you forever. The referral bonus is credited in USDT, withdrawal is possible from 100 USDT.',
    refBallance: 'Available',
    generateRefCode: 'Generate code',
    enterTRC20address: 'Enter address TRC20',
    enterAmount: 'Enter amount',
    withdrawalAll: 'Withdraw all funds',
    refFrozen: 'On withdrawal',
    withdraw: 'Withdraw',
    withdrawals: 'Withdraws',
    address: 'Address',
    amountTitle: 'Amount',
    status: 'Status',
    cards: 'Cards',
    bankTitle: 'Bank',
    number: 'Number',
    settings: 'Settings',
    changePassword: 'Change password',
    editProfile: 'Edit profile',
    addCard: 'Add card',
    deleteAccount: 'Delete account',
    enterNewPassword: 'Enter new password',
    enterNewPasswordAgain: 'Enter new password again',
    enterOldPassword: 'Enter old password',
    cancel: 'Cancel',
    save: 'Save',
    enterCardNumber: 'Enter card number',
    enterBankName: 'Enter bank name',
    enterFullName: 'Enter full name',
    loadCardPhoto: 'Load card photo',
    deleteAccountText: 'To delete your account and all information associated with it, enter the word - BITCOIN',
    enterWord: 'Enter word',
    delete: 'Delete',
    enterEmail: 'Enter email',
    enterPhone: 'Enter phone',
    enterName: 'Enter name',
    enterSurname: 'Enter surname',
    noExchange: 'No exchanges',
    created: 'Created',
    sended: 'Sent',
    geted: 'Got',

    NEW: 'New',
    PENDING: 'Pending',
    PAID: 'Paid',
    COMPLETED: 'Completed',
    TIMEEND: 'Time end',
    AMLRISK: 'AML risk',
    CANCELED: 'Canceled',
    ERROR: 'Error',
    PARTIALLYPAID: 'Partially paid',
    account: 'Account',
    noHistory: 'No history',
    noCards: 'No cards',

    exchange: 'Exchange',
    feedback: 'Feedbacks',
    services: 'Services',
    news: 'News',
    offers: 'Offers',
    rules: 'Rules',
    aml: 'KYC / AML',
    contact: 'Contacts',
    partnerProgram: 'Partner program',
    rates: 'Rates',
    faq: 'FAQ',
    chart: 'Charts',
    login: 'Login',
    markets: 'Markets',
    orders: 'Orders',
    wallets: 'Wallets',
    partners: 'Partners',
    redirects: 'Redirects',
    statistics: 'Statistics',
    users: 'Users',
    pages: 'Pages',
    blockLists: 'Black list',
    logout: 'Logout',
    orderPageMetaTitle: 'Order',

    registration: 'Registration',
    passwordAgain: 'Repeat password',
    enterPassword: 'Enter password',
    password: 'Password',
    enterPasswordAgain: 'Enter password again',
    refCode: 'Referral code',
    enterRefCode: 'Enter your referral code',
    signupAgree1: 'I agree with',
    signupAgree2: 'terms and conditions of service',
    signUp: 'Register',
    isAccount: 'Already have an account?',

    noAccount: 'Don`t have an account yet?',
    forgotPassword: 'Forgot your password?',
    chooseAccountType: 'Select account type',
    user: 'User',
    monitoring: 'Monitoring',
    partner: 'Partner program',
    partnerTitle: 'Partner',
    restorePassword: 'Password recovery',
    restore: 'Restore',
    getRestoreInstruction: 'Get login instructions',

    partnerRegistration: 'Partner Registration',

    commission: 'Commission',
    balance: 'Balance',
    link: 'Link',
    ratesLink: 'Rates link',
    createWithdrawalRequest: 'Create a withdrawal request',
    withdrawalHistory: 'Withdrawal history',
    noWithdrawalHistory: 'No withdrawal history',
    title404: 'We didn`t find anything, it`s time to go home',
    goHome: 'Home',
    commissionHistory: 'Commission history',

    date: 'Date',
    profit: 'Profit',

    feedbackComponentTitle: 'Reviews from our clients',
    feedbackComponentSubtitle: 'Our latest orders',
    openAllFeedback: 'View all reviews',
    marketsOverwie: 'Market overview',
    marketsOverwieCoinName: 'Name',
    marketsOverwieRate: 'Rate $',
    marketsOverwieChange: 'Change per day',
    marketsOverwieMarketcap: 'Capitalization',
    marketsOverwieTradingVolume: 'Trading volume',
    buyBtn: 'Buy',
    chartBtn: 'Chart',
    workflowComponentTitle: 'How it works',
    workflowComponentSubtitle: 'Our service has gone through many stages of evolution to give users the best cryptocurrency exchange experience.',
    workflowComponent1: 'Infrastructure',
    workflowComponent1sub: 'Our service is based on advanced technologies and our developers keep up with modern technologies.',
    workflowComponent2: 'Coins',
    workflowComponent2sub: 'We are constantly adding new directions for exchange, if you do not find what you need, just write to the manager and he will be able to find a way out.',
    workflowComponent3: 'Commissions',
    workflowComponent3sub: 'We try to keep commissions at a minimum level so that you can freely convert and purchase cryptocurrency.',
    workflowComponent4: 'Data',
    workflowComponent4sub: 'All data processed by our service is stored on the most secure servers and you don’t have to worry about security.',
    newsComponentTitle: 'Learn and earn',
    newsComponentSubtitle1: 'The latest news from the world of finance and cryptocurrency.',
    newsComponentSubtitle2: 'He who owns the information owns the world (Nathan Rothschild)',
    newsComponentBtn: 'Read all news',
    partnersComponentTitle: 'Our partners',
    partnersComponentSubtitle: 'The list of our partners is constantly updated, you can also join our team.',
    registrationAsPartner: 'Register as a partner',
    askManeger: 'Check with the manager for details',
    createAccount: 'Create an account',

    pageFeedbacksTitle: 'Reviews',
    pageFeedbacksSubtitle: 'Why users trust us and choose us',
    pageFeedbacksBtn: 'Make an exchange',
    pageFeedbacksSubjectTitle1: 'Many directions',
    pageFeedbacksSubjectSubtitle1: 'Thanks to our platform, you can get acquainted with cryptocurrencies and choose from a variety of exchange options',
    pageFeedbacksSubjectTitle2: 'Optimization',
    pageFeedbacksSubjectSubtitle2: 'Our developers have optimized the platform to make it convenient for you to use it from any device and anywhere in the world',
    pageFeedbacksSubjectTitle3: 'Safety',
    pageFeedbacksSubjectSubtitle3: 'Our product is built using the most advanced security technologies, all your data and assets are always absolutely safe',

    pageServicesSubtitle1: 'On this page you can familiarize yourself with the entire list of our services and services that we provide.',
    pageServicesSubtitle2: 'If you have questions or haven’t found the service you need, contact us via telegram -',

    pageNewsTitle: 'All the most important things from the world of cryptocurrencies',
    noData: 'No data',

    orderLoginFormTitle: 'An account with this email exists, please log in to your account to add a card',
    orderRateTitle: 'Exchange rate',
    orderCreateTitle: 'Order created',
    orderPayoutTitle: 'Recipient`s account',
    orderEmailTitle: 'User contact email',
    orderPhoneTitle: 'User contact phone',
    orderUserContactDataTitle: 'User contact information',
    orderCardTitle: 'Card for payment',
    orderCardNumberTitle: 'Card number',
    orderNameTitle: 'Recipient`s name',
    orderChooseCardTitle: 'Selecting a card',
    orderWaitForCardTitle: 'We receive payment details, it will take a few moments',
    orderChoose: 'Choose',
    cancelOrder: 'Cancel order',
    iPaid: 'I paid',
    refreshOrder: 'Update order',
    contactManadger: 'Contact the manager',
    writeFeedback: 'Enter your review of our work...',
    loadMore: 'Load more',
    cookieTitle: 'We use cookies.',
    okay: 'Ok',
    leaveFeedback: 'Leave feedback',
    thanksFeedback: 'Thanks for your feedback',

    tgManager: 'Manager',

    mailWithInstructionSended: 'Email with instructions sent',
    cardAddedSucces: 'Card selected successfully',
    errorCardChoose: 'Card selection error',
    userAlreadyCreated: 'This user already exists',
    chechImgLoaded: 'Receipt photo uploaded',
    vaitCardVerif: 'Wait for card verification',
    chooseCard: 'Select a card',
    fillAllFieldAndLoadCard: 'Fill in all fields and load the map',
    imgCardLoaded: 'Card photo uploaded',
    feedbackAdded: 'Review saved',
    doYouRealyWantToCancelOrder: 'Are you sure you want to cancel your application and refuse the fixed rate?',
    hide: 'Collapse',
    show: 'More details',
    userFeedbackTitle: 'User',
    directionFeedbackTitle: 'Direction',
    currencys: 'Currencies',
    time: 'Time',
    refCodeGenerated: 'Code created',
    withdrawCreated: 'The withdraw has been sent for processing',
    withdrawMin100: 'Withdrawal available from 100 USDT',
    withdrawSetMin100: 'Please enter an amount greater than 100 USDT',

    widthwalCreated: 'Withdraw created',
    type: 'Type',
    privetKey: 'Privet key',
    widthwal: 'Withdraw',
    copy: 'Copy',
    privetKeyCopied: 'Private key copied',
    active: 'Active',
    enterOrderID: 'Enter order ID',
    enterAddress: 'Enter address',
    enterWalletType: 'Select wallet type',
    findRefetch: 'Find / Refetch',
    reset: 'Reset',
    used: 'Used',

    deleteUserAgree: 'Are you sure you want to delete the user?',
    blockUserAgree: 'Are you sure you want to block',
    ban: 'Block',
    done: 'Done!',
    errorUserCalculate: 'User calculation error',
    roles: 'Roles',
    userData: 'User data',
    userOrderVolume: 'Volume of orders',
    refBalance: 'Ref Balance',
    refOwner: 'Referrer',
    refComission: 'Commission from referrals',
    currency: 'Currency',
    rate: 'Rate',
    addressForSend: 'Output address',
    chooseStatus: 'Select status',
    cardTitle: 'Map',
    open: 'Open',
    start: 'Start',
    end: 'End',
    totalOrders: 'Total orders',
    volume: 'Volume',
    medianVolume: 'Average volume',
    medianProfit: 'Average profit',
    medianRate: 'Average rate',
    allProfit: 'Total profit',
    getTitle: 'Received',
    sendTitle: 'Sent',
    enterIP: 'Enter IP',
    choosePartner: 'Choose a partner',
    notMonitoring: 'Not monitoring',
    monitoringOrRid: 'Monitoring / RID',
    submitDeleteCoin: 'Are you sure you want to delete the coin?',
    hideSettings: 'Hide settings',
    showSettings: 'Show settings',
    maintenance: 'Tech work',
    xmlStatus: 'Parsing by XML',
    coinName: 'Display name',
    coinSymbol: 'Coin symbol',
    coinChain: 'Network / City / Bank',
    coinLogo: 'Logo',
    coinType: 'Type of coin',
    coinCode: 'Currency code',
    activeDirection: 'Active directions',
    depositFields: 'Deposit fields',
    withdrawFields: 'Output fields',
    coinSymbolsEnd: 'Signs after the decimal point',
    depositSymbols: 'Rounding the output',
    depositCommission: 'Deposit commission',
    withdrawCommission: 'Withdrawal commission',
    coinReserve: 'Currency reserve',
    coinPosition: 'Position in the list',
    officeAddress: 'Office address',
    mapLink: 'Link to the map',
    walletType: 'Wallet type',
    crypto: 'Cryptocurrency',
    tgTitle: 'Telegram',
    tag: 'Tag',
    namesTitle: 'Names',
    walletTitle: 'Wallet',
    sbpPhone: 'Number SBP',
    bankAccountNumberTitle: 'Bank account number',
    custom: 'Custom',
    canGet: 'Get',
    canSend: 'Send',
    addCoin: 'Add coin',
    deleteCoin: 'Delete selected coin',
    fillDirectionDescription: 'Fill in the exchange description',
    market: 'Market',
    fetchedRate: 'Exchange rate',
    fixRate: 'Fixed rate',
    profitRate: 'Margin / Rate',
    min: 'Minimum',
    max: 'Maximum',
    updateRates: 'Update rates',
    saveDirection: 'Save directions',
    notActive: 'Not active',
    deletePartnerAgree: 'Are you sure you want to remove your partner?',
    enterReason: 'Enter the reason',
    partnerName: 'Name',
    commissionFromProfit: 'Profit commission',
    removeBan: 'Unblock',
    chooseComponentsToAddToPage: 'Select the components to be added to the page.',
    header: 'Header',
    description: 'Description',
    enterDescriptionWithHtml: 'Enter description with HTML tags',
    faqPage: 'Questions and Answers',
    question: 'Question',
    answer: 'Answer',
    deleteFAQ: 'Delete "Question - Answer"',
    addFAQ: 'Add "Question - Answer"',
    components: 'Components',
    loadPhoto: 'Upload photo',
    pasteLinkForSiteLogo: 'Insert a link to the site logo',
    pasteTGSupportLink: 'Insert link to telegram support',
    text: 'Text',
    footerLeftText: 'Paste the text in the lower left corner',
    HTMLFooterLeft: 'HTML footer Left Corner',
    enterHTMLFooterLeft: 'Insert content into the left corner of the footer',
    HTMLFooterRight: 'HTML footer right corner',
    enterHTMLFooterRight: 'Insert content into the right corner of the footer',
    showPartners: 'Show partners?',
    showSitemap: 'Display sitemap?',
    chooseLinkForFooter: 'Select which links will be available in the sitemap in the footer',
    enterHeaderSection: 'Enter section title',
    gapFromTopInPX: 'Indent from top component on pages in pixels',
    enterGap: 'Enter indent',
    linksForPartners: 'Links to partners and their logo',
    partnerLink: 'Link to partner',
    enterLink: 'Enter link',
    partnerPhoto: 'Link for image',
    addPartner: 'Add a partner',
    officeLocationList: 'List of your offices with addresses and maps',
    enterGMlink: 'Paste the link from Google map',
    addLocation: 'Add location',
    showAllOrdersInFeedbacks: 'Show all completed order in feedbacks',
    orderHeader: 'Order title',
    walletHeader: 'Wallet title',
    addressHeader: 'Address title',
    instructionHeader: 'Instruction title',
    enterSectionHeader: 'Enter section title',
    linkForManager: 'Link to manager',
    enterText: 'Enter text',
    instructionTIMEEND: 'Instructions for order Expired - TIMEEND',
    instructionAMLRISK: 'Instructions for order AML risk - AMLRISK',
    instructionERRORDETAILS: 'Instructions for order with wrong details -- ERRORDETAILS',
    instructionCANCELED: 'Instructions for order Canceled - CANCELED',
    instructionCOMPLETED: 'Instructions for order Completed - COMPLETED',
    instructionPAID: 'Instructions for order Paid - PAID',
    instructionPARTIALLYPAID: 'Instructions for order partially paid - PARTIALLYPAID',
    instructionCryptoCrypto: 'Instructions for order crypto -> crypto (NEW, PENDING)',
    instructionCryptoFiat: 'Instructions for order crypto -> fiat (NEW, PENDING)',
    instructionFiatCrypto: 'Instructions for order с fiat -> crypto (NEW, PENDING)',
    instructionCryptoCard: 'Instructions for order crypto -> card (NEW, PENDING)',
    instructionCardCrypto: 'Instructions for order card -> crypto (NEW, PENDING)',
    setOrAddCardText: 'Text when adding or selecting a card',
    addCardText: 'Text when a new user adds a card',
    cardInstruction: 'General instructions in the card section',
    techAlert: 'Technical Notice',
    enterHeader: 'Enter a title',
    promoAlert: 'Promotional notice',
    fullDescription: 'Detailed description',
    jivoLink: 'Link to JIVO chat',
    metrikaScript: 'Script Yandex.Metrika',
    enterScript: 'Enter script',
    metrikaLink: 'Link Yandex.Metrika',
    vantajsLink: 'Link to vantajs background',
    configVanta: 'VantaJS configuration',
    enterConfig: 'Enter configuration',
    customCSS: 'Custom css styles',
    enterCss: 'Enter css',
    showTimerOnBtn: 'Display timer on button?',
    widthPayForm: 'Pay form width in pixels',
    enterWidth: 'Enter width',
    defaultExchangeDirection: 'Default exchange direction on the main page',
    enterRequest: 'Enter your query',
    chooseReferral: 'Select a referral',
    agreeDeleteOrder: 'Are you sure you want to delete the order?',
    refOwnerId: 'ID referee',
    userSend: 'Send',
    userPaid: 'Paid',
    userGet: 'Receives',
    refOwnerProfit: 'Referrer`s profit',
    depositAddress: 'Deposit address',
    cardId: 'Card ID',
    withdrawUser: 'Output to user',
    recipients: 'Checks',
    orderInfo: 'Note',
    takeToWork: 'Accept for work',
    noRate: 'There is no required rate',
    idTime: 'ID / Time',
    statusRefOwner: 'Status / Referrer',
    sendPaid: 'Send / Paid',
    pauInfo: 'Details',
    deleteMarketAlert: 'This market is included in the calculation of rates, after deleting the market, update the rates page so that the directions where this market is involved are calculated correctly.',
    confirmDeleteMarket: 'Are you sure you want to delete the market?',
    addMarket: 'Add market',
    cross: 'Cross',
    test: 'Test',
    update: 'Refresh',
    sell: 'Sell',
    buy: 'Buy',
    newDirection: 'New direction',
    userId: 'User ID',
    userEmail: 'User email',
    deleteCard: 'Delete card',
    reason: 'Reason',
    blockedIps: 'Blocked IPs',
    blockedUsers: 'Blocked users',
    blockedPartners: 'Blocked partners',

    loading: 'Loading',
    online: 'Online',
    offline: 'Offline',
    onlineOperatorInfo: 'The operator is online and all orders will be processed as soon as possible.',
    offlineOperatorInfo: 'The operator is offline, the processing time of orders may increase.',
    initPayout: 'We are making the payment',
    yes: 'Yes',

    promotionTitle: 'Leave a review and get up to 500 rubles !',
    promotionSubtitle1: 'Make an exchange, leave a review about us and get up to 500 rubles! (100 for each review)',
    promotionSubtitle2: 'To receive a bonus, send your order id and a link to the review to the support service via Telegram.',
    promotionSubtitle3: 'Below are all the platforms where you can leave a review. Just click on the name to go.',
  }
};

function getLanguageFromCookie() {
  const match = document.cookie.match(new RegExp('(^| )language=([^;]+)'));
  try {
    const lang = (new URLSearchParams(window.location.search)).get('lang')
    if (lang === 'ru' || lang === 'en') {
      return lang
    }
  } catch (e) { console.log(e) }
  return match ? match[2] : 'ru'; // Если cookie не найдены, по умолчанию используем 'ru'
}

// Установим локаль на основе cookie
const language = getLanguageFromCookie();
Vue.prototype.$dictionary = dictionary[language];

export default dictionary;