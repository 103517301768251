/**
* @see: https://www.npmjs.com/package/vue-api-service#usage
*/
export default {
  // new api from mine back
  globalParams: {
    method: 'GET',
    url: '/global/params/:lang'
  },
  setRedirect: {
    method: 'POST',
    url: '/global/redirect'
  },
  updateMaintenance: {
    method: 'POST',
    url: '/global/params'
  },

  setAdminStatus: {
    method: 'POST',
    url: '/global/online'
  },

  setReceipt: {
    method: 'POST',
    url: '/order/setReceipt'
  },

  deleteCardMutation: {
    method: 'DELETE',
    url: '/card/:id'
  },

  setWorker: {
    method: 'POST',
    url: '/order/setWorker'
  },

  getBans: {
    method: 'GET',
    url: '/global/bans'
  },
  setBanIp: {
    method: 'POST',
    url: '/global/setBanIp'
  },
  removeBanIp: {
    method: 'POST',
    url: '/global/removeBanIp'
  },
  setBanUser: {
    method: 'POST',
    url: '/global/setBanUser'
  },
  removeBanUser: {
    method: 'POST',
    url: '/global/removeBanUser'
  },
  setBanPartner: {
    method: 'POST',
    url: '/global/setBanPartner'
  },
  removeBanPartner: {
    method: 'POST',
    url: '/global/removeBanPartner'
  },

  fetchFeedbacks: {
    method: 'GET',
    url: '/order/feedbacks'
  },
  fetchNews: {
    method: 'GET',
    url: '/news/all/:lang'
  },

  addCardMutation: {
    method: 'POST',
    url: '/card'
  },
  updateCardMutation: {
    method: 'PUT',
    url: '/card/:id'
  },
  getCards: {
    method: 'GET',
    url: '/card'
  },

  getCoins: {
    method: 'GET',
    url: '/coins'
  },
  getRate: {
    method: 'GET',
    url: '/rates'
  },
  updateXML: {
    method: 'POST',
    url: '/rates/updateXML'
  },
  sendOrder: {
    method: 'POST',
    url: '/order/create'
  },
  getOrder: {
    method: 'GET',
    url: '/order/:id'
  },
  cancelOrder: {
    method: 'PUT',
    url: '/order/cancel/:id'
  },
  userPay: {
    method: 'PUT',
    url: '/order/pay/:id'
  },
  sendFeedback: {
    method: 'PUT',
    url: '/order/feedback/:id'
  },

  uploadFile: {
    method: 'POST',
    url: '/file/upload'
  },

  initUser: {
    method: 'POST',
    url: '/auth/initUser'
  },
  registrationPartner: {
    method: 'POST',
    url: '/auth/registrationPartner'
  },
  registration: {
    method: 'POST',
    url: '/auth/registration'
  },
  login: {
    method: 'POST',
    url: '/auth/login'
  },
  resetPassword: {
    method: 'POST',
    url: '/auth/resetPassword'
  },

  getPartner: {
    method: 'GET',
    url: '/partner'
  },

  getUser: {
    method: 'GET',
    url: '/user'
  },
  updateUser: {
    method: 'PUT',
    url: '/user'
  },
  updatePassword: {
    method: 'PUT',
    url: '/user/password'
  },
  updatePasswordPartner: {
    method: 'PUT',
    url: '/partner/password'
  },
  deleteUser: {
    method: 'DELETE',
    url: '/user'
  },
  getReferralCode: {
    method: 'GET',
    url: '/user/refcode'
  },
  refWithdraw: {
    method: 'POST',
    url: '/user/refWithdraw'
  },
  refWithdrawPartner: {
    method: 'POST',
    url: '/partner/refWithdraw'
  },

  // ADMIN
  updatePartnerByAdmin: {
    method: 'PUT',
    url: '/partner/:id'
  },
  getAdminUsers: {
    method: 'GET',
    url: '/user/all'
  },
  getAdminPartners: {
    method: 'GET',
    url: '/partner/all'
  },
  getUserByAdmin: {
    method: 'GET',
    url: '/user/:id'
  },
  updateUserByAdmin: {
    method: 'PUT',
    url: '/user/:id'
  },
  getAdminOrders: {
    method: 'GET',
    url: '/order'
  },
  updateOrder: {
    method: 'PUT',
    url: '/order/:id'
  },
  setPayCard: {
    method: 'PUT',
    url: '/order/card/:id'
  },
  updateRatesByAdmin: {
    method: 'POST',
    url: '/rates/update'
  },
  deleteUserByAdmin: {
    method: 'DELETE',
    url: '/user/:id'
  },
  refStatusUpdate: {
    method: 'POST',
    url: '/user/refStatus'
  },
  deleteOrder: {
    method: 'DELETE',
    url: '/order/:id'
  },
  deletePartnerByAdmin: {
    method: 'DELETE',
    url: '/partner/:id'
  },
  getAdminWallets: {
    method: 'GET',
    url: '/wallet/all'
  },

  updateCoinByAdmin: {
    method: 'PUT',
    url: '/coins/:id'
  },
  saveCoinByAdmin: {
    method: 'POST',
    url: '/coins'
  },
  deleteCoinByAdmin: {
    method: 'DELETE',
    url: '/coins/:id'
  },
  
  getRedirectsByAdmin: {
    method: 'GET',
    url: '/redirect'
  },
  getPagesByAdmin: {
    method: 'GET',
    url: '/pages'
  },
  updatePageByAdmin: {
    method: 'PUT',
    url: '/pages/:id'
  },
  createPageByAdmin: {
    method: 'POST',
    url: '/pages/create'
  },
  deletePageByAdmin: {
    method: 'DELETE',
    url: '/pages/:id'
  },

  getComponentsByAdmin: {
    method: 'GET',
    url: '/components'
  },
  updateComponentByAdmin: {
    method: 'PUT',
    url: '/components/:id'
  },

  getMarketsByAdmin: {
    method: 'GET',
    url: '/markets'
  },
  getMarketByAdmin: {
    method: 'GET',
    url: '/markets/:id'
  },
  updateMarketByAdmin: {
    method: 'PUT',
    url: '/markets/:id'
  },
  saveMarketByAdmin: {
    method: 'POST',
    url: '/markets'
  },
  deleteMarketByAdmin: {
    method: 'DELETE',
    url: '/markets/:id'
  },

  withdrawCryptoByGOD: {
    method: 'POST',
    url: '/wallet/withdraw'
  },
  setRoleByGOD: {
    method: 'Put',
    url: 'user/role/:id'
  },
}
