<template>
  <div class="footer">
    <div class="footer__item">
      <div class="logo">
        <a href="https://rbankexchange.io">
          <img
            :src="header?.content?.logoLink"
            alt="Logo"
          >
        </a>
      </div>
      <div class="text">
        Telegram: 
        <a :href="footer?.content.telegramLink" target="_blank">
          {{$dictionary.tgManager}}
        </a>
      </div>
      <div class="text">
        Email: 
        <a href="mailto:noreply.rbexchange@gmail.com">
          <!-- noreply.rbexchange@gmail.com -->
          {{ $dictionary.mail }}
        </a>
      </div>
      <div class="text">
        {{$dictionary.address}}: Москва, Пресненская наб. 12
      </div>
    </div>
    <div class="footer__item links">
      <router-link
        v-for="(link, i) in footer?.content.sitemapLinks"
        :key="i"
        tag="a"
        :to="`/${link}`"
      >
        {{ link ? $dictionary[link] : $dictionary.exchange }}
      </router-link>
    </div>
    <div class="footer__item action">
      <router-link
        tag="a"
        :to="{ name: 'page-signup' }"
      >
        {{$dictionary.createAccount}}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data () {
    return {
    }
  },
  computed: {
    footer () {
      return this.$store.state.global.components.footer
    },
    header () {
      return this.$store.state.global.components.header
    }
  },
}
</script>
